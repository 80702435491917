/* Scoped styles for the Password Reset page */
.password-reset-container {
  padding: 20px;
  background: linear-gradient(135deg, rgb(76, 57, 81) 0%, #0F1513 50%, #00796B 100%);
}

.password-reset-container .message {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.256);
  border-radius: 8px;
}

.password-reset-container .message .ant-typography {
  text-align: center;
  margin-bottom: 20px;
  color: #00ffb3;
}

.password-reset-container .message .ant-form-item {
  margin-bottom: 15px;
}

.password-reset-container .message .ant-btn {
  width: 100%;
  font-size: 12px;
}

.password-reset-container .contact-container {
  margin-top: 30px;
  text-align: center;
}

.password-reset-container .contact-container .contact {
  font-size: 14px;
  margin-bottom: 20px;
}

.password-reset-container .contact-container .contact a {
  color: #28c7b4;
  text-decoration: none;
  font-weight: bold;
}

.password-reset-container .contact-container .contact a:hover {
  text-decoration: underline;
}
