/* App.css */

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

#root {
  height: 100%;
}

.ant-layout {
  min-height: 100vh;
  background: linear-gradient(135deg, #4e3754 10%, #0f1513 60%, #01463e 90%);
  overflow: hidden;
}

/* .logo {
  display: flex;
  margin-top: 25px;
}

.logo img {
  width: 193px;
  height: 56px;
} */

.ant-menu-horizontal {
  background: transparent !important;
  flex-grow: 1;
  justify-content: end;
}
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover {
  background: transparent;
}
.ant-layout-content {
  padding: 50px;
}
.ant-menu.ant-menu-dark .ant-menu-item-selected {
  color: #00ffb3 !important;
  background: transparent !important;
  border-bottom: #00ffb3;
  border-width: 2px;
}

.intro-section {
  display: flex;
  background: transparent;
  padding: 50px;
  border-radius: 10px;
  justify-content: space-between;
  align-items: center;
}
.community-section {
  display: flex;
  flex-direction: column;
  background: transparent;
  padding: 50px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 50px;
  text-align: center;
}
.community-section button {
  margin-top: 20px;
  align-self: center;
}
.transform-logo {
  margin-top: 40px;
  width: 520px;
}

.intro-section h1 {
  color: #00ffb3;
  font-size: 50px;
  padding-right: 20px;
  line-height: 66px;
}
.community-section h2 {
  color: #00ffb3;
  font-size: 35px;
}

.intro-section p {
  color: #ffffff;
  font-size: 25px;
  align-self: flex-start;
  padding-right: 20px;
  line-height: 33.6px;
}

.intro-section button {
  margin-top: 20px;
  align-self: flex-start;
}
.introduction-container {
  padding: 30px;
  background: #bebdbd34;
}
.introduction-container h3 {
  color: #00ffb3;
  font-size: 25px;
}
.introduction-container p {
  font-size: 20px;
}
.features-section,
.choices-section {
  margin-top: 50px;
}

.users-section h2,
.features-section h2,
.choices-section h2 {
  color: #ffffff;
  text-align: center;
  font-size: 30px;
}

/* .ant-card-meta-avatar .ant-avatar {
    background-color: #1890ff;
  }
  
  .ant-card-meta-title {
    color: #1890ff;
  } */

/* App.css */
.users-section {
  padding-top: 30px;
  padding-left: 10px;
  padding-right: 10px;
  margin: 10px;
  overflow: hidden;
  text-align: center;
}
.user-first-sec {
  display: flex;
}
.user-second-sec {
  display: flex;
}
.user-card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 20px;
}
.user-card-text {
  text-align: left;
}

.user-card-text h3 {
    font-size: 25px;
    color: #1de9b6;
}
.user-card-text p {
    font-size: 20px;
}

.user-card-section h1 {
  color: #00ffb3;
  margin-left: 30px;
}
.user-card-section p {
  margin-left: 30px;
}
.user-card-img {
  max-width: 30%;
  max-height: 30%;
  margin-right: 15px;
}
.feature-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  text-align: center;
  justify-content: space-between;
}
.feature-card h4 {
  color: #1de9b6;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 25px;
}
.feature-card p {
  text-align: center;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}

.home-page-card {
  background: transparent !important;
  border-radius: 10px;
  color: #fff;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

.home-page-card .ant-card-body {
  color: #fff;
}

.home-page-card-title {
  color: #00ffb3;
}

.home-page-card .ant-card-meta-description {
  color: #fff;
}

.ant-layout-footer {
  text-align: center;
  background: transparent;
  color: #fff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.logo img {
  height: 40px; /* Adjust the height as needed */
}

.menu {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  flex-wrap: wrap; /* Allows wrapping on smaller screens */
}

.menu .ant-menu-item {
  margin: 0 10px; /* Add some spacing between menu items */
}

.login-button {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes the button to the far right */
}

@media (max-width: 768px) {
  .header {
    justify-content: center;
  }

  .menu {
    justify-content: center;
    flex-wrap: wrap;
  }

  .login-button {
    margin-top: 10px;
    order: 3; /* Ensures the button is always the last item */
  }

  .logo img {
    height: 30px; /* Adjust the height for mobile */
  }
  /*  */
  .intro-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
    flex-direction: column-reverse;
  }
  .intro-section h1 {
    color: #00ffb3;
    font-size: 28px;
    padding-right: 10px;
    line-height: 35px;
    text-align: center;
  }
  .intro-section p {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
    line-height: 21.6px;
  }
  .intro-section button {
    margin-top: 20px;
    margin-left: 35%;
  }
  .introduction-container h3 {
    color: #00ffb3;
    font-size: 22px;
    font-weight: 600;
  }
  .introduction-container p {
    color: #f4f4f4;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .users-section h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .user-card-text h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .user-card-text p {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .features-section h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .feature-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    text-align: center;
    justify-content: space-between;
  }
  .feature-card-img img {
    width: 200px;
    height: auto;
  }
  .feature-card-text h4 {
    font-size: 14px;
    font-weight: 400;
    line-height: 8px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #1de9b6;
    padding-left: 10px;
  }
  .feature-card-text p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.04em;
    text-align: left;
    padding-left: 10px;
  }
  .community-section h2 {
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .community-section p {
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: 0.04em;
    text-align: center;
  }
}

.scroll-container {
  padding: 20px;
  background-color: transparent;
  color: white;
  text-align: center;
}

.scroll-arrow {
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 4px;
  z-index: 1;
}

.section-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #fff;
}

.scroll-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.scroll-content {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  scroll-behavior: smooth;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.scroll-content::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.card {
  width: 300px; /* Ensure 4 cards fit on the screen */
  height: 300px; /* Square aspect ratio */
  margin: 0 10px;
  background-size: cover;
  background-position: center;
  position: relative;
  flex: 0 0 auto;
  border-radius: 8px;
  overflow: hidden;
  display: flex; /* Added */
  justify-content: center; /* Added */
  align-items: center; /* Added */
}

.card-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(30, 30, 30, 0.674);
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-text {
  color: white;
  font-size: 30px;
  font-weight: 700;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: center; /* Added */
  z-index: 1;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .card {
    width: 280px; /* Ensure 3 cards fit on the screen */
  }
}

@media (max-width: 768px) {
  .scroll-container {
    padding: 10px;
  }

  .section-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }

  .scroll-content {
    padding: 5px;
  }

  .card {
    width: 150px; /* Ensure 2 cards fit on the screen */
    height: 150px; /* Maintain square aspect ratio */
    margin: 0 5px;
  }

  .card-text {
    padding: 3px 7px;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
  }

  .scroll-arrow {
    padding: 5px;
  }
}

@media (max-width: 480px) {
  .scroll-container {
    padding: 5px;
  }

  .section-title {
    font-size: 18px;
    margin-bottom: 5px;
  }

  .scroll-content {
    padding: 5px;
  }

  .card {
    width: 120px; /* Ensure 1 card fits on the screen */
    height: 120px; /* Maintain square aspect ratio */
    margin: 0 3px;
  }

  .card-text {
    padding: 2px 5px;
  }

  .scroll-arrow {
    display: none; /* Hide arrows on very small screens */
  }
}
