/* App.css */

.layout-dashboard .ant-layout {
    background: linear-gradient(135deg, #2c3b3e, #1a1f21);
    position: relative;
    min-height: 100vh;
    width: 100%;
    flex-shrink: 0;
    color: white;
    overflow: hidden; /* Prevents overflow */
  }
  
  .ant-layout-header {
    background: transparent;
    display: flex ;
    align-items: center;
    justify-content: space-between;
  }
  
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    padding: 0 50px;
  }
  .ant-menu-horizontal{
    align-items: center;
  }
  
  
  .site-layout-content {
    padding: 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    overflow: hidden; /* Prevents overflow */
  }
  
  .statistic-card {
    background: #000000 !important;
    border-radius: 8px;
    border: none !important;
    overflow: hidden; /* Prevents overflow */
    max-width: 100%;
    box-shadow: 0 0 15px rgba(255, 255, 255, 0.265) !important;
    text-align: center;
  }
  .ant-card{
    background: rgba(255, 255, 255, 0.1) ;
    border-radius: 8px;
    border: none;
    overflow: hidden; /* Prevents overflow */
    max-width: 100%;
  }
  
  .ant-card-head {
    color: white;
  }
  
  .ant-statistic-title {
    color: #1DE9B6;
    font-weight: 400;
    font-size: 18px;
  }
  
  .ant-statistic-content {
    color: #ffffff;
  }
  .ant-menu-dark .ant-menu-item-selected {
    background-color: #1890ff !important; /* Change to your desired color */
    color: white !important; /* Ensure the text color is readable */
  }
  
  .ant-table-tbody > tr > td {
    background: transparent ;
    border-bottom: none ;
    color: white;
  }
  .xyz {
    color: #bebebeba;
    font-weight: bold;
  }
  .ant-table-tbody > tr > td:first-child {
    padding-left: 10px;
  }
  .ant-row {
    justify-content: center;
    margin-bottom: 16px;
  }
  .page-title {
    color: white;
    font-size: 24px;
    margin-bottom: 24px;
  }
  
  /* Breadcrumb */
  .ant-breadcrumb {
    color: white;
  }
  
  .ant-breadcrumb-link {
    color: white;
  }
  
  .ant-breadcrumb-separator {
    color: white;
  }
  
  /* Responsive Styles */
  @media (max-width: 576px) {
    /* .header {
      padding: 0 20px;
      flex-direction: column;
      align-items: flex-start;
    } */
  
    .header .menu {
      margin-top: 10px;
    }
  
    .site-layout-content {
      padding: 16px;
    }
  
    .page-title {
      font-size: 20px;
    }
  
    .ant-card {
      margin-bottom: 16px;
    }
  
    .ant-card-body {
      padding: 12px; /* Reduce padding for small screens */
    }
  
    .ant-card-body > div {
      overflow-x: auto; /* Prevent chart overflow */
    }
  
    .recharts-wrapper {
      max-width: 100%; /* Ensure charts do not overflow */
    }
  }
  
  @media (max-width: 768px) {
    /* .header {
      padding: 0 30px;
    } */
  
    .site-layout-content {
      padding: 20px;
    }
  
    .page-title {
      font-size: 22px;
    }
  }
  
  @media (min-width: 992px) {
    .ant-row .ant-col {
      padding-left: 8px;
      padding-right: 8px;
    }
  
    .ant-row .ant-col > .ant-card {
      margin-bottom: 16px;
    }
  }
  