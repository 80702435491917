/* forgotPassword.css */

/* Style for the container */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 0vh;
  padding: 20px;
}

/* Character image section */
.character img {
  width: 100%;
  max-width: 350px;
  height: auto;
}

/* Info section where the form is located */
.info-forgot-password {
  background-color: #cdcdcd2b;
  color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Title */
.info .message h2 {
  margin-bottom: 20px;
}

/* Input field style */
.ant-input-email {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ddd;
}

/* Button style */
.input-submit-button {
  width: 100%;
  max-width: 300px;
}


/* Contact card section */
.contact-container {
  margin-top: 30px;
  text-align: center;
}

.contact a {
color: #28c7b4;
  text-decoration: none;
}

.contact a:hover {
  text-decoration: underline;
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .info {
    width: 100%;
    padding: 15px;
  }

  .ant-input-email,
  .input-submit-button {
    max-width: 100%;
  }
}
