.header {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 10px 20px;
  flex-wrap: wrap;
  font-size: 20px;
}
.ant-menu-item-selected > span > a {
  color: #1DE9B6 !important;
  /* text-decoration: underline ;
  text-underline-offset: 5px; */
}
.ant-menu-item-selected > span > a::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px; /* Thickness of the underline */
  background-color: #1DE9B6;
  transform: translateY(-17px); /* Offset to create space between text and underline */
}

.logo img {
  height: 60%; /* Adjust the height as needed */
}

.home-drawer .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
    color: #1DE9B6 !important;
}
.home-drawer .ant-menu-item:hover{
    color: #fafafa;
}

.menu-container {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.menu {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0;
}

.menu .ant-menu-item {
  margin: 0 10px; 
  font-size: 15px;/* Add some spacing between menu items */
}

.login-button {
  display: flex;
  align-items: center;
  margin-left: auto; /* Pushes the button to the far right */
}

.mobile-menu-icon {
  display: none;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  color: #1DE9B6;
}

.home-drawer .ant-drawer-content-wrapper {
  width: 75% !important;
}

.ant-menu {
  background: transparent !important;
}

.home-drawer .ant-drawer-content {
  background-color: #212121;
}

.home-drawer .ant-drawer-header {
  background: #212121;
}

.home-drawer .ant-drawer-close {
    color: #fff;
}

.home-drawer .ant-drawer-footer {
  border: none;
  padding: 0px 0px;
}

/* */

.drawer-logo {
  height: 40px; /* Adjust the logo size as needed */
}

.home-drawer .ant-drawer-body {
  padding: 0;
}

.drawer-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 16px;
  text-align: center;
}

@media (max-width: 800px) {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    flex-wrap: wrap;
  }
  .menu-container {
    display: none;
  }

  .login-button {
    margin-top: 10px;
    order: 3; /* Ensures the button is always the last item */
  }

  .logo img {
    height: 35px; /* Adjust the height for mobile */
  }

  .mobile-menu-icon {
    display: flex;
  }
  .ant-menu-item a{
    color: #fafafa !important;
  }
  .ant-menu-item-selected > span > a {
    color: #1DE9B6 !important;
  }
  .ant-menu-item-selected > span > a::after {
    display: none; /* Offset to create space between text and underline */
  }
}
