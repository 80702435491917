
  
  /* Section styling */
  .section {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: transparent;
    border-radius: 8px;
    
  }
  
  /* Title styles */
  .title {
    font-size: 28px;
    color: #1de9b6 !important;
    margin-bottom: 16px;
    text-align: center;
  }
  
  .subtitle {
    font-size: 22px;
    color: #ffffff !important;
    margin-top: 24px;
    margin-bottom: 12px;
  }
  
  .sub-subtitle {
    font-size: 18px;
    color: #e6e6e6 !important;
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  /* Paragraph styling */
  .description {
    font-size: 16px;
    line-height: 1.6;
    color: #ffffff;
    margin-bottom: 12px;
  }
  
  /* List styling */
  .description-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .description-list li {
    font-size: 16px;
    line-height: 1.6;
    color: #ffffff;
    margin-bottom: 8px;
  }
  
  /* Link styling */
  a {
    color: #1890ff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Text strong styles */
  strong {
    color: #1de9b6;
    font-weight: 600;
  }
  