.layout {
  background: #f9f9f900;
}

.site-layout-content {
  background: transparent;
  padding: 0px !important;
  margin: 0;
  min-height: 280px;
}

.company-overview {
  margin-top: 50px;
  width: 100%;
  height: 100vh;
  background: url("../aboutus/coverPage.svg") no-repeat center center fixed;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay {
  background: rgba(0, 0, 0, 0.443); /* Adjust opacity as needed */
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-row {
  width: 100%;
  text-align: center;
}

.content-box {
  background: rgba(15, 15, 15, 0.642);
  padding: 20px;
  border-radius: 8px;
  color: white;
}

.content-box h1 {
  color: #1de9b6;
  margin-bottom: 20px;
  font-size: 35px;
  margin-top: 10px;
}

.content-box h6 {
  margin-bottom: 5px;
  color: #b3b3b3;
  font-size: 26px;
}

.content-box p {
  margin: 0;
  color: white;
  font-size: 26px;
}

@media (max-width: 576px) {
  .content-box {
    padding: 10px;
  }
}

/*  */
/* FocusArea.css */
.focus-area {
  text-align: center;
  padding: 30px;
  margin-left: 30px;
  margin-right: 30px;
  background: transparent;
  color: white;
  margin-top: 20px;
}

.focus-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #fff;
}

.hexagon {
  position: relative;
  width: 200px;
  /* margin: 0 auto; */
  aspect-ratio: 1.1547; /* height = width * sqrt(3) / 2 */
  background-color: transparent;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1 100px; /* Adjusted for responsiveness */
  margin: 10px;
}

.hexagon-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7d3cff;
}

.hexagon-content {
  text-align: center;
  font-size: 2.2em;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.hexagon:hover .hexagon-inner {
  transform: scale(1.1);
  transition: transform 0.3s ease;
}

@media (max-width: 576px) {
  .focus-title {
    font-size: 1.5em;
  }

  .hexagon {
    width: 150px;
  }

  .hexagon-content {
    font-size: 1em;
  }
}

/*  */
.flagship-product {
  text-align: center;
  color: #fff;
  padding-top: 50px;
}

.flagship-product h2 {
  color: #fff;
  font-size: 30px;
}

.product-feature {
  text-align: left;
}

.founder-info {
  background-color: #f0f2f55a;
  margin-left: -20px;
  margin-right: -10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.founder-info h2 {
  color: #fff;
}

.founder-image {
  width: 70%;
  padding: 10px;
}

.founder-details {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ceo-details {
  align-items: center;
  flex-direction: column;
  text-align: center;
  /* margin-left: 60px; */
}
  .ceo-details a {
    font-size: 18px;
    line-height: 0px;
  }

.founder-text {
  color: #f0f2f5;
  margin-bottom: 10px;
  font-size: x-large;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 50px;
}

.founder-text-title {
  color: #1de9b6;
}

.csr-initiatives {
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
}
.csr-initiatives img {
  width: 50%;
  margin-top: 30px;
}
.csr-initiatives h2 {
  color: #fff;
  margin-bottom: 40px;
  margin-top: 15px;
  font-size: 30px;
}
.csr-container {
  flex-direction: row;
}
.csr-feature {
  text-align: left;
}
.csr-feature h4 {
  color: #1de9b6;
  margin-top: 30px;
  font-size: 25px;
}
.csr-feature text {
  font-size: 20px;
}

@media (max-width: 768px) {
  .company-overview {
    width: 100%;
    height: 50vh;
    background: url("../aboutus/coverPage.svg") no-repeat center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .content-row {
    width: 100%;
    text-align: center;
  }
  .overlay {
    position: relative; 
  }
  .infinity-img{
    position: absolute;
    top: 1px;
    right: 1px;
    width: 90px; 
    height: auto; 
    margin: 1%;
  }
  .content-box {
    background: rgba(15, 15, 15, 0.443);
    padding: 10px;
    border-radius: 8px;
    color: white;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .content-box h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .company-details h6 {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .company-details p {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .focus-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .hexagon-content {
    font-size: 12px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .focus-area {
    text-align: center;
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
    background: transparent;
    color: white;
    margin-top: 60px;
  }
  .focus-hexagon-conatiner {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .hexagon {
    width: 100px;
    height: 100px;
  }
  .flagship-product h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .founder-image {
    width: 70%;
    top: 20px;
    left: 123px;
    gap: 0px;
  }
  .ceo-details h2 {
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .ceo-details a {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .csr-feature h4 {
    font-size: 22px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .csr-feature text {
      font-size: 18px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.04em;
      text-align: left;
    }

  .founder-text {
    color: #f0f2f5;
    margin-bottom: 0px;
    margin-left: 20px;
    font-size: 18px;
  }
  .csr-initiatives h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }
}
