/* CareerPage.css */

.section {
  margin-bottom: 50px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 20px;
  font-size: 26px;
  font-weight: 700;
}

.title {
  color: #1de9b6;
  margin-bottom: 20px;
}

.description {
  color: #fff;
  margin-bottom: 20px;
  font-weight: 400;
}

/*  */
.hiring-section {
  background-color: transparent;
  color: #fff;
  padding: 2rem;
  margin-top: 40px;
}

.hiring-section .hiring-title {
  text-align: left;
  color: #1de9b6;
  font-size: 40px;
  font-weight: 500;
}

.hiring-job {
  padding: 1rem;
}

.hiring-job-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding-right: 30px;
}

.job-details h3 {
  font-size: 25px;
  font-weight: 400;
}

.job-details p {
  margin: 0;
  font-weight: 400;
  color: #9e9e9e;
}

.hiring-job-content .ant-btn {
  margin-left: 1rem;
}

.apply-btn {
  background-color: rgb(0, 0, 0);
  padding-left: 50px;
  padding-right: 50px;
  color: #1de9b6;
  border-radius: 8px;
  border-color: #1de9b6;
  font-size: 20px;
  font-weight: 400;
  height: 37px;
}

/*  */
.join-us-row {
  background-color: transparent;
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: left;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
}

.join-us-header {
  color: #1de9b6;
  text-align: left;
  margin-bottom: 20px;
}

.join-us-col {
  padding: 10px; /* Adjust padding if needed */
}

.join-us-box {
  display: flex;
  background-color: #fefefe38;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  height: 130px;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  overflow: hidden;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .hiring-job-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .hiring-job-content .ant-btn {
    margin-left: 0;
    margin-top: 1rem;
    align-self: center;
  }
  .section {
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .description {
    margin-bottom: 0px;
  }

  .section .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
    margin-bottom: 5px;
  }
  .join-us-header {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
    margin-left: 10px;
  }
  .join-us-box {
    display: flex;
    width: 100px;
    background-color: #fefefe38;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    height: 80px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    overflow: hidden;
  }
  .join-us-row {
    background-color: transparent;
    display: flex;
    flex-direction: row;
    margin-left: 0px;
    margin-right: 0px;
  }
  .join-us-col {
    flex: 0 0 0%;
    padding: 0px;
    margin: 3px;
  }
  .hiring-section .hiring-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
    margin-top: -20px;
    margin-left: 20px;
  }
  .job-details h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .hiring-section{
    padding: 0px;
    margin-left: 0px;
  }
  .apply-btn {
    background-color: rgb(0, 0, 0);
    padding-left: 25px;
    padding-right: 25px;
    color: #1de9b6;
    border-radius: 8px;
    border-color: #1de9b6;
    font-size: 14px;
    font-weight: 400;
    height: 35px;
  }
  .hiring-job-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
  }
}
