.layout-default-layout-signin {
  background: linear-gradient(
    135deg,
    #003e37 0%,
    #0f1513 50%,
    #003e37 100%
  ) !important;
}

.create-acc-btn {
  width: 48%;
  margin-left: 10px;
}
.forgot-password-text {
  color: #ffffff;
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
}

.custom-button {
  line-height: 40px !important;
}
@media only screen and (max-width: 768px) {
  .layout-default-layout-signin {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
}