.container {
  background-color: transparent;
  /* height: 60vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.character {
  display: flex;
  justify-content: center;
  align-items: center;
}

.info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}

.fill-form {
  margin-top: 20px;
  background-color: #32cd32;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  color: white;
  cursor: pointer;
  align-self: flex-start;
  padding-left: 20px;
  padding-right: 20px;
  height: 33px;
}
.contact-container {
  padding: 30px;
}
.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
.contact a {
  font-size: 20px;
  margin-top: -20px;
  color: #fff;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .character {
    width: 266px;
  }
  .fill-form {
    margin-top: 20px;
    background-color: #32cd32;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: white;
    cursor: pointer;
    align-self: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .message p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .contact p {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: center;
  }
  .contact a {
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.04em;
    text-align: left;
  }
}
