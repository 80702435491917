/* Custom CSS for ordering elements */
.blog-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  color: #fff;
}
.blogs-container h2 {
  color: #fff;
  margin-bottom: 50px;
  font-weight: 700;
  font-size: 26px;
}
.blogs-container {
  margin-top: 50px;
  margin-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.blog-row .image-col,
.blog-row .text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.blog-row .image-col {
  align-items: center;
  margin-top: 50px;
}

.blog-text h4 {
 color: #1de9b6;
}



@media (max-width: 768px) {
  .blog-row {
    flex-direction: column;
  }

  .blog-row .image-col {
    order: -1;
    margin-bottom: 16px;
  }

  .blog-row .text-col {
    order: 1;
  }
  .blog-text h4 {
    font-size: 18px !important;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
  .blogs-container h2 {
    font-size: 16px;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.04em;
    text-align: center;
  }

}

@media (min-width: 768px) {
  .blog-row:nth-child(odd) .image-col {
    order: 1;
  }

  .blog-row:nth-child(odd) .text-col {
    order: 1;
  }

  .blog-row:nth-child(even) .image-col {
    order: 1;
  }

  .blog-row:nth-child(even) .text-col {
    order: 1;
  }

  .blog-image {
    width: 40%;
    height: auto;
    border-radius: 10%;
    margin-bottom: 20px;
  }

  .blog-text h4 {
    margin: 0 0 8px;
    font-size: 26px;
    font-weight: 700;
    margin-bottom: 20px;
  }

.blogs-container h2 {
  text-align: center;
  margin-bottom: 24px;
}

}



.blog-text p {
  margin: 0 0 16px;
  font-weight: 400;
}

.blog-author {
  display: block;
  font-weight: bold;
  margin-bottom: 4px;
  color: #fff;
}

.blog-date {
  display: block;
  color: rgb(255, 255, 255);
  margin-bottom: 16px;
}

.read-more {
  color: #1b79f8;
  text-decoration: underline;
}
