.products-container {
  /* text-align: left; */
  margin-bottom: 50px;
  margin-left: 20px;
  margin-right: 20px;
  padding-left: 20px;
}
.products-container img {
  width: 70%;
  margin-top: 100px;
  margin-right: 20px;
  margin-left: 20px;
}
.products-container h2 {
  color: #fff;
  margin-bottom: 50px;
}
.csr-container {
  flex-direction: row;
}
.product-text {
  text-align: left;
  margin-right: 20px;
}
.product-text h4 {
  color: #1de9b6;
  margin-top: 100px;
}
.product-text h4,
p {
  font-size: 25px;
  line-height: 40px;
  font-weight: 300;
}

/* Custom CSS for ordering elements */
.product-row {
  display: flex;
  flex-wrap: wrap;
}

.product-row .image-col,
.product-row .text-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 767.98px) {
  .product-row {
    flex-direction: column;
  }

  .product-row .image-col {
    order: -1;
    margin-bottom: 16px;
  }

  .product-row .text-col {
    order: 1;
  }
}

@media (min-width: 768px) {
  .product-row:nth-child(odd) .image-col {
    order: 1;
  }

  .product-row:nth-child(odd) .text-col {
    order: 2;
  }

  .product-row:nth-child(even) .image-col {
    order: 2;
  }

  .product-row:nth-child(even) .text-col {
    order: 1;
  }
}

@media (max-width: 768px) {
  .products-container img {
    width: 128px;
    align-self: center;
    margin-top: 60px;
  }
  .product-text h4 {
    margin-top: 0px;
  }
  .products-container {
    margin-top: 50px;
    margin-left: 10px;
    margin-right: 10px;
  }
  .product-text h4,
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.04em;
    text-align: left;
  }
}

.products-container .product-text {
  margin-top: 16px;
}
