.app-footer {
  background-color: #00796b !important;
  color: #ffffff;
  padding: 20px 0 5px 0px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
  flex-direction: row;
}

.image-and-title-container {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
}
.footer-title-container {
  display: flex;
  margin-bottom: 10px;
  width: 100%;
  flex-wrap: wrap;
}

.footer-image {
  max-width: 80%;
  height: auto;
}

.footer-text-container {
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
}

.title-text {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.title-footer {
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}

.wealth-text {
  font-size: 12px !important;
  color: #ffffff;
  line-height: 0px !important;
}

.footer-section {
  flex: 1 0 auto;
  margin-right: 10px;
  text-align: left;
  margin-top: 10px;
  margin-left: 20px;
}

.footer-title {
  color: #ffffff;
  font-size: 18px;
  /* margin-bottom: 10px; */
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-links li {
  margin-bottom: 5px;
}

.footer-links a {
  color: #ffffff;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-title a {
  color: #ffffff;
  text-decoration: none;
}

.footer-title a:hover {
  color: #ffffff;
  text-decoration: none;
}

.footer-social-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.footer-social-icons .anticon {
  font-size: 20px;
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer-content {
    justify-content: space-between;
    padding-top: 15px;
    display: flex;
    flex-direction: column;
  }

  .footer-section {
    margin-bottom: 20px;
  }

  .image-and-title-container {
    margin-bottom: 20px;
  }

  .footer-section.about-section,
  .footer-section.products-section,
  .footer-section.more-section {
    flex: 0 0 auto;
    width: calc(33.33% - 10px); /* 33.33% width with a small margin between */
  }
  .footer-links {
    display: none;
  }

  .footer-section {
    text-align: center;
  }

  .footer-social-icons {
    margin-top: 20px;
    margin-left: 40%;
  }
  .footer-image {
    width: 168px;
    height: 141px;
    top: 17px;
    left: 34px;
    gap: 0px;
  }
  .footer-text-container h3 {
    font-size: 30px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
  }
  .footer-text-container p {
    font-size: 14px;
    font-weight: 500;
    line-height: 27px;
    text-align: left;
  }
}

@media (min-width: 769px) and (max-width: 865px) {
  .footer-content {
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 0; 
  }
  .footer-section {
    flex: 1;
    margin-right: 20px;
    text-align: left;
  }
  .footer-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; 
    width: 100%; 
    margin-right: 20px; 
  }
  .footer-text-container h2 {
    font-size: 24px; 
    margin: 0;
  }
  .footer-text-container p {
    font-size: 14px;
    margin: 0;
    line-height: 1.2;
  }
  .footer-image {
    max-width: 100%; 
    height: auto;
  }
  .footer-social-icons {
    margin-top: 10px;
    display: flex;
    gap: 15px;
  }
}