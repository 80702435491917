.full-screen-modal .ant-modal {
  width: 100vw !important;
  height: 100vh !important;
  max-width: 100% !important;
  top: 0 !important;
  padding: 0;
  margin: 0;
  overflow: hidden;
}
.ant-modal{
  max-width: calc(100vw) !important;
}
.full-screen-modal .ant-modal-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.full-screen-modal .ant-modal-body {
  flex: 1;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.layout-default.layout-signin {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.layout-signin .signin {
  width: 100%;
  max-width: 400px;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-height: 400px;
}
